import React, { useState, useEffect } from 'react'
import { useNavigate, Link } from "react-router-dom";
import  './login.css'
import user_icon from '../../assets/person.png'
import Add from '../../assets/addAvatar.png'
import email_icon from '../../assets/email.png'
import password_icon from '../../assets/password.png'
import {db, auth, storage} from "../../firebase"
import { createUserWithEmailAndPassword, updateProfile, signInWithEmailAndPassword } from "firebase/auth";
import {addDoc, collection, getDocs, query, where, doc, setDoc} from "@firebase/firestore"
import { ref, uploadBytesResumable, getDownloadURL } from "@firebase/storage"
import { display } from '@mui/system';



  
  


const LoginForm = () => {
  const [err, setErr] = useState(false);
  const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [action, setAction] = useState("Registracija");
    const [email, setEmail] = useState('');
    const [showEmailMessage, setShowEmailMessage] = useState(false);
    const [showPassMessage, setShowPassMessage] = useState(false);
    const [errorLogin, setShowErrorLogin] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [password, setPassword] = useState('');
  const [DBpassword, setDBPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [userData, setUserData] = useState([]);
  const [displayName, setDisplayName] = useState('');
  const [avatar, setAvatar] = useState("");
  
 
  const handleSubmitRegister = async (e) => {
    setLoading(true);
    const file = avatar;
    console.log( file);
   
    try {
      //Create user
      const res = await createUserWithEmailAndPassword(auth, email, password);
      const date = new Date().getTime();
      console.log("date " + date)
      console.log("storage")
      console.log(storage)
      console.log(`${displayName + date}`)
      //Create a unique image name
      const storageRef = ref(storage, `${displayName + date}`);
      console.log("storageREf")
      console.log(storageRef)


      await uploadBytesResumable(storageRef, file).then(() => {
        getDownloadURL(storageRef).then(async (downloadURL) => {
          console.log("downloadURL " + downloadURL)
          try {

            //create user on firestore
            await setDoc(doc(db, "users", res.user.uid), {
              uid: res.user.uid,
              displayName,
              email,
              photoURL: downloadURL,
            });

            //create empty user chats on firestore
            await setDoc(doc(db, "userChats", res.user.uid), {});
            navigate("/");
          } catch (err) {
            console.log(err);
            setErr(true);
            setLoading(false);
          }
        });
      });
    } catch (err) {
      setErr(true);
      setLoading(false);
    }
  }

  const handleValidEmail = (e) => {
    const inputEmail = e.target.value;
    setEmail(inputEmail);
    // Provjerava je li adresa u dobrom formatu
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValid(emailRegex.test(inputEmail));
    setShowEmailMessage(false);
  };

  const handleFileChanged = (e) => {
    const file = e.target.files[0];
    console.log(file);
    setAvatar(file);
  }

  const handleDisplayName = (e) => {
    setDisplayName(e.target.value);
  }


  const readPassword = async () => {
    try {

      const q = query(
        collection(db, 'Users'),
        where('email', '==', email)
      );

      const querySnapshot = await getDocs(q);

      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      try{
      setDBPassword(data[0].password);
      }
      catch {
      }
      setUserData(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };



  const handleProctaj = () => {
    readPassword();
  };

 
  const handleValidPassword = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    // Provjerava je li password dug 8 karaktera
    setIsPasswordValid(newPassword.length >= 8);
    setShowPassMessage(false);
  };


  //Provjerava da li su mail adresa i password u rangeu i otvara iducu rutu
   const handleButtonPrijavaClick = async (e) => {
    if (action === 'Prijava')
    {
        if(isValid)
        {
            if(isPasswordValid)
            {
              try {
               await signInWithEmailAndPassword(auth, email, password);
                navigate("/")
            }
            catch (err) {
              console.log("usao u catch");
              setErr(true);
            }
          }
            else
            {
              setShowPassMessage(true);
            }
        }
        else
        {
            setShowEmailMessage(true);
        }
    }
    else
    {
        setAction("Prijava");
    }
  };
 
  const handleButtonRegistracijaClick = () => {
    if (action === 'Registracija')
    {
        if(isValid)
        {
            if(isPasswordValid)
            {
                    try
                    {
                      console.log("usao u try registracije");

                      handleSubmitRegister();
                    }
                    catch(e) {
                        console.log(e + " usao u catch");
                    }
            }
            else
            {
                setShowPassMessage(true);
            }
        }
        else
        {
            setShowEmailMessage(true);
        }
    }
    else
    {
        setAction("Registracija");    }
  };
    return (     
                <div className='container'>
                <div className="header">
                    <div className="text">{action}</div>
                    <div className="underline"></div>
                </div>
                <div className="inputs">
                    {action==="Prijava"?<div/>:
                    <div className="input">
                    <img src={user_icon} alt="" />
                    <input type="text" placeholder='Name' onChange={handleDisplayName} />
                </div>}
               
                    <div className="input"  style={{  borderColor: isValid ? '#4cceac' : 'red' }}>
                        <img src={email_icon} alt="" />
                        <input type="email" placeholder='Email' onChange={handleValidEmail} />
                    </div>
                    {showEmailMessage? <p style={{ color: 'red', marginLeft: '60px'}}>Molimo unesite validnu e-mail adresu</p> : null}
     
                    <div className="input" style={{  borderColor: isPasswordValid ? '#4cceac' : 'red' }}>
                        <img src={password_icon} alt="" />
                        <input type="password" placeholder='Password' onChange={handleValidPassword}/>
                    </div>
                    <input required style={{ display: "none" }} type="file" id="file" onChange={handleFileChanged}/>
                    <label htmlFor="file">
                      <img src={Add} alt="" />
                      </label>
                    {showPassMessage? <p style={{ color: 'red', marginLeft: '60px'}}>Password mora biti dug minimalno 8 karaktera</p> : null}
                    {errorLogin? <p style={{ color: 'red', marginLeft: '60px'}}>Pogrešna prijava</p> : null}
                </div>
                {action==="Registracija"?<div/>: <div className="forgot-password">Zaboravljena lozinka? <span>Posalji novu</span></div>}
                
                <div className="submit-container">
                    <div className={action==="Prijava"?"submit gray":"submit"} onClick={handleButtonRegistracijaClick}>Registracija</div>
                    <div className={action==="Registracija"?"submit gray":"submit"} onClick={handleButtonPrijavaClick}>Prijava</div>

                </div>
                
            </div>
              )}
        
         
export default LoginForm;