import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC0PZEGH6v1Qrgwe9IL6uZWc7Z5NpQ--PM",
  authDomain: "asa-bolnica.firebaseapp.com",
  projectId: "asa-bolnica",
  storageBucket: "asa-bolnica.appspot.com",
  messagingSenderId: "989264481682",
  appId: "1:989264481682:web:9eddac016406741fed054b",
  measurementId: "G-XENGKPGL09"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const storage = getStorage();
export const db = getFirestore(app);
