import React from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { AuthContextProvider } from "./context/AuthContext";
import { ChatContextProvider } from "./context/ChatContext";
import { ColorModeContext } from "./theme";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthContextProvider>
  <ChatContextProvider>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ChatContextProvider>
</AuthContextProvider>
);
