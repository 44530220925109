import React, { useState } from 'react';
import { Box, Button, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataEmail } from "../../data/mockData";
import Header from "../../components/Header";
import axios from 'axios';
import Sidebar from "../../scenes/global/Sidebar";


const Email = (isSidebar) => {
  const [expandedRowId, setExpandedRowId] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState('1');
  let [params1, setParams]=useState(null);
  
  function ScrollToBottomButton() {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth', // You can use 'auto' or 'smooth' for scrolling behavior
      });
    };

   

  const handleSendEmail = async (params) => {
    try {
      // Call the async main function and wait for its completion
      console.log(inputValue);

      const email  = params.row.email;
      const naslov  = params.row.naslov;
      const emailText = params.row.mailText;

    
      await axios.post('/send-email', {emailText, email, inputValue, naslov});
      console.log('Email sending request sent');

      // After the function is complete, you can perform any additional actions here
      console.log('Email sent successfully');
    } catch (error) {
      // Handle any errors that may occur during email sending
      console.error('Email sending failed:', error);
    }
  }

  const handleExpandRow = (params) => {
    
    setExpandedRowId(params.row.id);
    setValue(params.row.mailText);
    setParams(params);
    ScrollToBottomButton  ();

  };

  const handleInputChange = (e) => {
    // Update the inputValue state when the input changes
    setInputValue(e.target.value);
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
 
  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "name",
      headerName: "Ime i prezime",
      flex: 1,
      cellClassName: "name-column--cell",
    }, 
    {
      field: "email",
      headerName: "E-mail adresa",
      flex: 1,
    },
    {
      field: "naslov",
      headerName: "Naslov maila",
      flex: 1,
    },
    {
      field: "accessLevel",
      headerName: "Access Level",
      flex: 1,
      renderCell: (params) => {
        return (
          <button style={{
            backgroundColor: '#7FFFD4',
            color: '#',
            borderRadius: '8px',
            padding: '12px 2px',
            border: 'none',
            cursor: 'pointer',
            display: 'block',
            margin: 'auto',
            marginTop: '5px',
          }} 
          onClick={()=> (params)}
          >
          Otvori Email
        </button>
        );
      },
    },
  ];

  return (
    <div style={{display:'flex'}} className='app'>
    {isSidebar && <Sidebar isSidebar={isSidebar} />}
    <Box m="20px" width={'-webkit-fill-available'}>
    <Box m="20px">
      <Header title="E-mail" subtitle="Upravljanje e-mail porukama" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiDataGrid-row": {
            borderBottom: "none !importvant",
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid  rows={mockDataEmail}
        columns={columns}
        onRowClick={(params) => handleExpandRow(params)}
          />
      </Box>


      {expandedRowId !== null && (
        <div style= {{marginTop: '20px'}}>
             <p style={{ fontSize: '17px' }}>{params1.row.naslov} </p>
          <textarea readOnly style={{ width: '100%', height: 'auto', overflow: 'visible', minHeight: '250px ', maxHeight: '500px', maxWidth: '100%', fontSize: '16px',  borderColor: '#498f81', // Set your desired border color here
          borderWidth: '3px', // You can adjust the border width
          borderStyle: 'solid',
          borderRadius: '8px',
        backgroundColor: '#edf2f1' }}
            type="text"
            value = {value}
            onChange={handleInputChange}
          />
      <textarea
        style={{
          width: '100%',
          height: '40px',
          fontSize: '16px',
          marginTop: '30px',
          minHeight: '100px',
          borderColor: '#498f81', // Set your desired border color here
          borderWidth: '3px', // You can adjust the border width
          borderStyle: 'solid',
          borderRadius: '8px'
        }}
        type="text"
        value={inputValue}
        onChange={handleInputChange}
      />
   
    
          <button style={{
            backgroundColor: '#7FFFD4',
            color: '#',
            borderRadius: '8px',
            padding: '12px 2px',
            border: 'none',
            cursor: 'pointer',
            display: 'block',
            margin: 'auto',
            marginTop: '5px',
          }} 
          onClick={()=>handleSendEmail(params1)}
          >
          Posalji Email
        </button>
          
      </div>
      
      )}
    </Box>
    </Box>
    </div>
  );
    
};
export default Email;
