import React from 'react';
import { Box, Button, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataViber } from "../../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";


const Viber = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "name",
      headerName: "Ime i prezime",
      flex: 1,
      cellClassName: "name-column--cell",
    }, 
    {
      field: "email",
      headerName: "E-mail adresa",
      flex: 1,
    },
    {
      field: "phone",
      headerName: "Broj telefona",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
    },
    {
      field: "accessLevel",
      headerName: "",
      flex: 1,
      renderCell: ({ row: { access } }) => {
        return (
          <button style={{
            backgroundColor: '#7FFFD4',
            color: '#',
            borderRadius: '8px',
            padding: '12px 24px',
            border: 'none',
            cursor: 'pointer',
            display: 'block',
            margin: 'auto',
            marginTop: '5px',
          }} >
          Otvori poruku
        </button>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      <Header title="Viber" subtitle="Upravljanje viber porukama" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiDataGrid-row": {
            borderBottom: "none !important",
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid checkboxSelection rows={mockDataViber} columns={columns} />
      </Box>
    </Box>
  );
};

export default Viber;
