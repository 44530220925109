

import React, { useState } from 'react'

import { ChatEngine, getOrCreateChat } from 'react-chat-engine'

const ViberChatBox = () => {
	const [username, setUsername] = useState('')

	function createDirectChat(creds) {
		getOrCreateChat(
			creds,
			{ is_direct_chat: true, usernames: [username] },
			() => setUsername('')
		)
	}

	function renderChatForm(creds) {
		return (
			<div>
				<input 
					placeholder='Username' 
					value={username} 
					onChange={(e) => setUsername(e.target.value)} 
				/>
				<button onClick={() => createDirectChat(creds)}>
					Create
				</button>
			</div>
		)
	}

	return (
		<ChatEngine
			height='100vh'
      projectID='d9730daa-9b0f-412a-aa8b-c57926a10de1'
      userName='kenanJ1'
      userSecret='NovaLozinka2016'
			renderNewChatForm={(creds) => renderChatForm(creds)}
		/>
	)
}

export default ViberChatBox;