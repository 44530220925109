import React, {useContext} from 'react';
import { Box } from "@mui/material";
import Team from "./scenes/team";
import Email from "./scenes/email";
import { useState } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { AuthContext } from "./context/AuthContext";
import Topbar from "./scenes/global/Topbar";
import Dashboard from "./scenes/dashboard";
import Contacts from "./scenes/contacts";
import Contact from "./scenes/contactUs";
import "./style.scss";
import Messenger from "./scenes/messenger";
import WhatsApp from "./scenes/whatsapp";
import Viber from "./scenes/viber";
import Sidebar from "./scenes/global/Sidebar";
import Invoices from "./scenes/invoices";
import Login from "./scenes/login";
import ViberChatBox from "./scenes/viberChatBox"
import ChatBox from "./scenes/chatBox"
import Form from "./scenes/form";
import Calendar from "./scenes/calendar";
import FAQ from "./scenes/faq";
import Bar from "./scenes/bar";

import Pie from "./scenes/Pie";
import Line from "./scenes/line";
import Geography from "./scenes/geography";



function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const { currentUser } = useContext(AuthContext);

  const ProtectedRoute = ({ children }) => {
    console.log(currentUser);
    if (!currentUser) {
      return <Navigate to="/login" />;
     
    }
    return children
  };
  

  return (  
    <ColorModeContext.Provider value={colorMode}>
    <ThemeProvider theme={theme}>
    <CssBaseline />
      <BrowserRouter>
      <Routes>
        <Route path="/">
        <Route
          index
          element={
            <ProtectedRoute>
              <Dashboard isSidebar={isSidebar}/>
            </ProtectedRoute>
          }
        />
        <Route path="login" element={<Login />} />
      </Route>
                <Route path="/email" element={<Email />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/messenger" element={<Messenger />} />
                <Route path="/viber" element={<Viber />} />
                <Route path="/viberchatbox" element={<ViberChatBox />} />
                <Route path="/chatbox" element={<ChatBox />} />
                <Route path="/whatsapp" element={<WhatsApp />} />
                <Route path="/contactUs" element={<Contact />} />
                <Route path="/team" element={<Team />} />
                <Route path="/form" element={<Form />} />
                <Route path="/calendar" element={<Calendar />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/bar" element={<Bar />} />
                <Route path="pie" element={<Pie />} />
                <Route path="/line" element={<Line />} />
               
    </Routes>
  </BrowserRouter>
  </ThemeProvider>
  </ColorModeContext.Provider>
  );
}


export default App;
