import React from 'react'
import Sidebar from '../../components/Sidebar'
import SidebarMain from "../../scenes/global/Sidebar";
import Chat from '../../components/Chat'
import { Box} from "@mui/material";


const chatBox = (isSidebar) => {
  return (
    <Box m="20px">
    <div style={{display:'flex'}} className='app'>
    {isSidebar && <SidebarMain isSidebar={isSidebar} />}
    <Box m="20px" width={'-webkit-fill-available'}>
    <div className='home'>
      <div className="container">
        <Sidebar/>
        <Chat/>
      </div>
    </div>
    </Box>
    </div>
    </Box>
    
  

  )
}

export default chatBox